import 'react-app-polyfill/ie11';

import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { FaSyncAlt } from 'react-icons/fa';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class AppEventTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refresh: 'Refresh'
    }
  }

  render() {

    const refreshEventData = (refreshEventDataFn) => {
      this.setState({
        refresh: 'Loading... '
      })
      refreshEventDataFn(
        () => {
          this.setState({
            refresh: 'Refresh'
          })
        },
      );
    }

    const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const eventDateFormatOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

    const columns = [{
      dataField: 'title',
      text: 'Band',
      sort: true,
      headerClasses: 'band-name',
      classes: 'summary',
      formatter: (cell, row) => <Link to={`/tickets/${row.id}-${cell.replace(/\s+/g, '_')}`}>{cell}</Link>,
    }, {
      dataField: 'venue.name',
      text: 'Venue',
      sort: true,
      headerClasses: 'venue-name',
      classes: 'location'
    }, {
      dataField: 'date',
      text: 'Gig Date',
      type: 'date',
      sort: true,
      formatter: (cell, row) => new Date(cell).toLocaleDateString("en-GB", eventDateFormatOptions),
      headerClasses: 'date',
      classes: 'dtstart'
    }, {
      dataField: 'price',
      text: 'Price',
      type: 'number',
      sort: true,
      formatter: (cell, row) => {
        if (cell === "0") {
          return "£tbc"
        } else {
          return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(cell).replace(/\D00$/, '')
        }
      },
      sortValue: (cell, row) => parseInt(cell),
      headerClasses: 'price'
    }];

    const defaultSorted = [{
      dataField: 'date',
      order: 'asc'
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        &nbsp; Showing {from} to {to} of {size} gigs
      </span>
    );

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      if (active) {
        activeStyle.backgroundColor = 'black';
        activeStyle.color = 'white';
      } else {
        activeStyle.backgroundColor = 'gray';
        activeStyle.color = 'black';
      }
      if (typeof page === 'string') {
        activeStyle.backgroundColor = 'white';
        activeStyle.color = 'black';
      }
      return (
        <li className="page-item" key={page}>
          <Button className="btn btn-light" onClick={handleClick} style={activeStyle}>{page}</Button>
        </li>
      );
    };

    const { SearchBar } = Search;

    const pageButtonOptions = {
      sizePerPageList: [20, 50, 100],
      pageButtonRenderer,
      showTotal: true,
      paginationTotalRenderer: customTotal
    };

    const { error, isLoaded, createDate, events, refreshEvents } = this.props;

    if (error) {
      return (
        <Container className="py-5">
          <p>Error: {error.message}</p>
          <Button onClick={() => refreshEventData(refreshEvents)} variant="outline-info" style={{ 'marginTop': '-3px' }}>{this.state.refresh} &nbsp; <FaSyncAlt style={{ 'marginTop': '-2px' }} /></Button>
        </Container>
      )
    } else if (!isLoaded) {
      return (
        <Container className="py-5">
          <p>Loading...</p>
          <Button onClick={() => refreshEventData(refreshEvents)} variant="outline-info" style={{ 'marginTop': '-3px' }}>{this.state.refresh} &nbsp; <FaSyncAlt style={{ 'marginTop': '-2px' }} /></Button>
        </Container>
      )
    } else {
      const ticketedEvents = events.filter(obj => obj.tickets.length > 0);
      return (
        <Container className="py-5">
          <p>
            Our list of Leeds gigs was last updated on {createDate.toLocaleDateString("en-GB", dateFormatOptions)}.
          </p>
          <ToolkitProvider keyField="id" data={ticketedEvents} columns={columns} search bootstrap4 condensed>
            {
              props => (
                <Container className="p-0">
                  <SearchBar {...props.searchProps} /> &nbsp; <Button onClick={() => refreshEventData(refreshEvents)} variant="outline-info" style={{ 'marginTop': '-3px' }}>{this.state.refresh} &nbsp; <FaSyncAlt style={{ 'marginTop': '-2px' }} /></Button>
                  <BootstrapTable id="event-table" striped hover bordered={false} condensed
                    defaultSorted={defaultSorted} pagination={paginationFactory(pageButtonOptions)}
                    rowClasses="vevent" {...props.baseProps} />
                </Container>
              )
            }
          </ToolkitProvider>
        </Container>
      )
    }
  };

};

export default AppEventTable;
