import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";

import {
  FaTicketAlt, FaInfo, FaCheck, FaTwitter, FaRssSquare
} from 'react-icons/fa';

import * as events from './events.js'

import AppEventTable from "./AppEventTable"

class AppHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      createDate: new Date(),
      events: []
    };
    // This binding is necessary to make `this` work in the callbacks
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    this.refreshEvents = this.refreshEvents.bind(this);
  }

  success = (data) => {
    console.log("Updating events data in state")
    this.setState({
      isLoaded: true,
      createDate: new Date(data.createDate * 1000),
      events: data.events
    });
  }

  error = (error) => {
    this.setState({
      isLoaded: true,
      error
    });
  }

  refreshEvents(callback) {
    events.refresh(this.success);
    callback();
  }

  componentDidMount() {
    events.get(this.success, this.error);
  }

  render() {
    return (
      <Container id="home" fluid="true" className="px-0">

        <Container id="intro" fluid="true" className="px-0 py-4">
          <Container className="py-5">
            <h1 className="display-4">
              <FaTicketAlt style={{ 'marginTop': '-10px' }} className="d-none d-md-inline-block" /> Leeds Gig Tickets
            </h1>
            <p className="lead">
              The primary resource for finding and buying tickets for Leeds gigs and festivals.
            </p>
          </Container>
        </Container>

        <AppEventTable error={this.state.error} isLoaded={this.state.isLoaded}
          createDate={this.state.createDate} events={this.state.events} refreshEvents={this.refreshEvents} />

        <Container id="features" fluid="true" className="my-5 px-0 py-3">
          <Container className="py-5">
            <Row className="text-center">
              <Col id="about" xs={12} sm={12} md={12} lg={4}>
                <Button size="lg" variant="primary" className="btn-circle btn-circle-lg">
                  <FaInfo size={70} />
                </Button>
                <h2>About</h2>
                <p className="text-muted">
                  Leeds Gig Tickets is an independent website that is updated daily to dig out and
                  share the latest ticket information and availability.
                </p>
                <p className="text-muted">
                  Our aim is to take the pain out of searching for Leeds concert tickets and we make
                  it easier for you to find and buy your gig or festival ticket.
                </p>
              </Col>
              <Col id="alerts" xs={12} sm={12} md={12} lg={4}>
                <Button size="lg" variant="success" className="btn-circle btn-circle-lg">
                  <FaCheck size={70} />
                </Button>
                <h2>Ticket Alerts</h2>
                <p className="text-muted">
                  We offer a feed to provide a ticket alert service to all visitors.  As soon as
                  we post new tickets, you will get to know about them.
                </p>
                <p>
                  <Button block size="lg" variant="success" href="https://www.leedsgigtickets.co.uk/feed">
                    <FaRssSquare style={{ 'marginTop': '-5px' }} /> &nbsp; Subscribe to our feed
                  </Button>
                </p>
              </Col>
              <Col id="twitter" xs={12} sm={12} md={12} lg={4}>
                <Button size="lg" variant="info" className="btn-circle btn-circle-lg text-white">
                  <FaTwitter size={70} />
                </Button>
                <h2>@leedsgigtickets</h2>
                <p className="text-muted">
                  We are on Twitter, where we announce gigs that have just gone on sale. Tweet to us if you are 
                  looking for Leeds tickets, or if you have a ticket to sell at face value. 
                </p>
                <p>
                  <Button block size="lg" variant="info" className="text-white" href="https://twitter.com/leedsgigtickets">
                    <FaTwitter style={{ 'marginTop': '-5px' }} /> &nbsp; Follow us @leedsgigtickets
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    )
  };

};

export default AppHome;
