import React from "react";
import { HashLink } from "react-router-hash-link";

import { Navbar, Nav, Container } from "react-bootstrap";
import { FaTicketAlt } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

class AppNavbar extends React.Component {

  render() {
    return (
      <Navbar fixed="top" bg="dark" variant="dark" expand="md">
        <Container>
          <Navbar.Brand as={HashLink} to="/#home"><FaTicketAlt style={{ 'marginTop': '-2px' }} /> &nbsp; Leeds Gig Tickets</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={HashLink} to="/#about">About</Nav.Link>
              <Nav.Link as={HashLink} to="/#alerts">Ticket Alerts</Nav.Link>
              <Nav.Link as={HashLink} to="/#twitter"><FaTwitter /> Twitter</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  };

};

export default AppNavbar;
