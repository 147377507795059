import ls from 'local-storage';

ls.backend(sessionStorage);

const dataDomain = "https://data.leedsgigtickets.co.uk";

export function getVenue(id, callback, errorFn) {
    var stKey = 'stVenueEvents-' + id, stEvents = ls.get(stKey);
    if (stEvents) {
        callback(stEvents);
    } else {
        console.log("Extracting events for venue %s from event list data", id);
        get(
            (data) => {
                var result = data;
                const venueEvents = result.events.filter(obj => obj.venue.id === id);
                result.events = venueEvents;
                ls.set(stKey, result);
                callback(result);
            },
            (error) => {
                console.log(error.message);
                ls.clear(stKey);
                errorFn(error);
            }
        );
    }
}

export function getId(id, callback, errorFn) {
    var stKey = 'stEvent-' + id, stEvent = ls.get(stKey);
    if (stEvent) {
        callback(stEvent);
    } else {
        console.log("Extracting event %s from event list data", id);
        get(
            (data) => {
                const event = data.events.find(x => x.id === id);
                if (event) {
                    ls.set(stKey, event);
                    callback(event);
                } else {
                    ls.clear(stKey);
                    errorFn({ message: "Could not find event" });
                }
            },
            (error) => {
                console.log(error.message);
                ls.clear(stKey);
                errorFn(error);
            }
        );
    }
}

export function refresh(callback) {
    // Save a backup in case we need to restore it
    var backup = ls.get('stEvents');
    // Clear the current data
    ls.clear('stEvents');
    get(
        (data) => {
            // Send the updated data back
            callback(data)
        },
        (error) => {
            // Restore from our backup
            ls.set('stEvents', backup);
            // Send the previous data back
            callback(backup)
        }
    );
}

export function get(callback, err) {
    var stEvents = ls.get('stEvents');
    if (stEvents) {
        callback(stEvents);
    } else {
        console.log("Downloading events data from server");
        window.fetch(dataDomain + "/leeds-events.json")
            .then(res => res.json())
            .then(
                (result) => {
                    ls.set('stEvents', result);
                    callback(result);
                },
                (error) => {
                    ls.clear('stEvents');
                    err(error)
                }
            )
    }
}