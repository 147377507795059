import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import he from 'he';

import {
  Container, Col, Row, Button, Card
} from "react-bootstrap";

import {
  FaTwitter, FaFacebookF, FaInfo
} from 'react-icons/fa';

import * as events from './events.js'

import AppTicket from "./AppTicket"
import AppVenueMap from "./AppVenueMap"

function AppEvent() {

  let { id } = useParams();
  id = id.split('-').shift();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [description, setDescription] = useState("We have no further information about the gig.");
  const [event, setEvent] = useState({
    id: id,
    title: "",
    venue: {
      id: null,
      name: "",
      address: "",
      city: ""
    },
    tickets: [],
  });

  const eventSuccess = (data) => {
    var tmp = "";
    data.tickets.forEach(item => {
      if (tmp <= "") { tmp = item.description }
    });
    if (tmp <= "") { tmp = description }
    setIsLoaded(true);
    setEvent(data);
    setDescription(tmp);
  };

  const eventError = (error) => {
    setIsLoaded(true);
    setError(error);
  }

  if (!error && !isLoaded) {
    events.getId(event.id, eventSuccess, eventError);
  }

  const meta = {
    title: event.title + ' Tickets | ' + event.venue.name + ' | Leeds | ' + event.dateStr,
    canonical: 'https://www.leedsgigtickets.co.uk/tickets/' + event.id,
    abstract: 'Buy tickets for gigs at ' + event.title + ' in Leeds.',
    description: 'Buy ' + event.title + ' gig tickets. Our online ticket finder has found concert tickets for ' + event.title + ' at ' + event.venue.name + ' on ' + event.dateStr,
    keywords: event.title + ', ' + event.venue.name + ', Leeds, gig, tickets, event, ticket'
  }

  if (error) {
    return (
      <Container className="py-5">
        <p>Error: {error.message}</p>
      </Container>
    )

  } else if (!isLoaded) {
    return (
      <Container className="py-5">
        <p>Loading...</p>
      </Container>
    )

  } else {
    return (
      <Container id="home" fluid="true" className="px-0">

        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="abstract" content={meta.abstract} />
          <meta name="keywords" content={meta.keywords} />
          <link rel="canonical" href={meta.canonical} />
          <meta property="og:url" content={meta.canonical} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
        </Helmet>

        <Container id="intro" fluid="true" className="px-0 py-3">
          <Container className="py-5">
            <h1 className="display-4">
              {event.title}
            </h1>
            <p className="lead">
              {event.venue.name}, {event.dateStr}
            </p>
            <p>
              <Button variant="primary" href={'https://www.facebook.com/sharer.php?u=' + window.location}><FaFacebookF style={{ 'marginTop': '-3px' }} /> &nbsp; SHARE</Button> &nbsp;
              <Button variant="info" className="text-white" href={'https://twitter.com/share?via=leedsgigtickets&url=' + window.location}><FaTwitter style={{ 'marginTop': '-3px' }} /> &nbsp; TWEET</Button>
            </p>
          </Container>
        </Container>

        <Container className="py-5">
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} className="pb-5">
              <p>
                The following agents are selling tickets for <strong>{event.title}</strong> at <strong>{event.venue.name}</strong> on <strong>{event.dateStr}</strong>.
              </p>
              <p>
                Face value tickets are priced from <strong>£{event.price > 0 ? event.price : "tbc"}</strong>.
              </p>
              {event.tickets.map((ticket, key) =>
                <AppTicket title={event.title} ticket={ticket} key={ticket.id} />
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <Card bg="dark" text="white">
                <Card.Header>
                  <Button size="md" variant="secondary">
                    <FaInfo size={28} />
                  </Button> &nbsp; About this event
                </Card.Header>
                <Card.Body>
                  <Card.Title>{event.title}</Card.Title>
                  <Card.Text id="event-description">
                    {he.decode(description)}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container id="features" fluid="true" className="my-5 px-0 py-3">
          <Container className="py-5">
            <Row>
              <Col xs={12} sm={12} md={5} lg={5} className="pb-3">
                <h2>{event.venue.name}</h2>
                <p>{event.venue.address}</p>
                <p>
                  Leeds Gig Tickets is updated every day to find the latest events and <Link to={`/venues/${event.venue.id}-${event.venue.name.replace(/\s+/g, '_')}`}>{event.venue.name} tickets</Link>.
                  Click through for all concerts at {event.venue.name} in Leeds.
                </p>
              </Col>
              <Col xs={12} sm={12} md={7} lg={7}>
                <AppVenueMap
                  venue={event.venue.name}
                  address={event.venue.address} />
              </Col>
            </Row>
          </Container>
        </Container>

      </Container>
    )
  };

};

export default AppEvent;
