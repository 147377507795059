import React from "react";

class AppVenueMap extends React.Component {

  render() {

    return (
      <iframe
        title="map"
        width="100%"
        height="400"
        frameBorder="0"
        allowFullScreen
        src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyBWqNasgxbUwD3s_9ocr1N6-iCZgrmK7Zs&q=' + encodeURI(this.props.venue) + ',' + encodeURI(this.props.address)}
      ></iframe>
    )
  };
};

export default AppVenueMap;
