import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as events from './events.js'
import * as venues from './venues.js'

import AppEventTable from "./AppEventTable"
import AppVenueMap from "./AppVenueMap"
import { useParams } from "react-router-dom";

function AppVenue() {

  let { id } = useParams();
  id = id.split('-').shift();

  const [eventError, setEventError] = useState(null);
  const [isEventLoaded, setIsEventLoaded] = useState(false);
  const [venueError, setVenueError] = useState(null);
  const [isVenueLoaded, setIsVenueLoaded] = useState(false);
  const [createDate, setCreateDate] = useState(new Date());
  const [venueEvents, setVenueEvents] = useState([]);
  const [venue, setVenue] = useState({
    id: id,
    name: "Venue not found"
  });

  const eventSuccessCallback = (data) => {
    setIsEventLoaded(true);
    setCreateDate(new Date(data.createDate * 1000));
    setVenueEvents(data.events);
  }

  const eventErrorCallback = (data) => {
    setIsEventLoaded(true);
    setEventError(data);
  }

  const venueSuccessCallback = (data) => {
    setIsVenueLoaded(true);
    setVenue(data);
  }

  const venueErrorCallback = (data) => {
    setIsVenueLoaded(true);
    setVenueError(data);
  }

  if (!isEventLoaded && !isVenueLoaded) {
    // Load Venue & Events data when the page loads.
    venues.getId(venue.id, venueSuccessCallback, venueErrorCallback);
    events.getVenue(venue.id, eventSuccessCallback, eventErrorCallback);
  }

  if (venueError) {
    return (
      <Container className="py-5">
        <p>Error: {venueError.message}</p>
      </Container>
    )
  } else if (!isVenueLoaded) {
    return (
      <Container className="py-5">
        <p>Loading...</p>
      </Container>
    )
  } else {
    const meta = {
      title: venue.name + ' | Tickets | Concerts',
      canonical: 'https://www.leedsgigtickets.co.uk/venues/' + venue.id,
      abstract: 'Buy tickets for gigs at ' + venue.name + ' in Leeds.',
      description: 'Buy ' + venue.name + ' gig tickets. Our online ticket finder has found concert tickets for gigs at ' + venue.name + ' in Leeds.',
      keywords: venue.name + ', Leeds, gig, tickets, event, ticket'
    }
    return (
      <Container id="home" fluid="true" className="px-0">

        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="abstract" content={meta.abstract} />
          <meta name="keywords" content={meta.keywords} />
          <link rel="canonical" href={meta.canonical} />
          <meta property="og:url" content={meta.canonical} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
        </Helmet>

        <Container id="intro" fluid="true" className="px-0 py-3">
          <Container className="py-5">
            <h1 className="display-4">
              {venue.name}
            </h1>
            <p className="lead">
              {venue.address}
            </p>
          </Container>
        </Container>

        <AppEventTable error={eventError} isLoaded={isEventLoaded}
          createDate={createDate} events={venueEvents} />

        <Container id="features" fluid="true" className="my-5 px-0 py-3">
          <Container className="py-5">
            <Row>
              <Col xs={12} sm={12} md={5} lg={5} className="pb-3">
                <h2>{venue.name}</h2>
                <p>{venue.address}</p>
                <p>
                  Leeds Gig Tickets is updated every day to find the latest {venue.name} tickets.
                </p>
              </Col>
              <Col xs={12} sm={12} md={7} lg={7}>
                <AppVenueMap
                  venue={venue.name}
                  address={venue.address} />
              </Col>
            </Row>
          </Container>
        </Container>

      </Container>
    )
  }
}

export default AppVenue;
