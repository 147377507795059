import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";

import AppNavbar from './AppNavbar';
import AppFooter from './AppFooter';
import AppHome from './AppHome';
import AppEvent from './AppEvent';
import AppVenue from './AppVenue';

import './App.scss';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class App extends React.Component {

  render() {
    const meta = {
      title: 'Leeds Gig Tickets - find and buy tickets for music gigs and festivals in Leeds',
      canonical: 'https://www.leedsgigtickets.co.uk',
      abstract: 'Buy tickets for festivals and gigs in Leeds.',
      description: 'Leeds Gig Tickets: Find concert tickets for gigs, festivals and other music events at all Leeds live music venues.',
      keywords: 'Leeds, gig, tickets, festival, event, ticket, festivals, gigs'
    }
    return (
      <Router>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="abstract" content={meta.abstract} />
          <meta name="keywords" content={meta.keywords} />
          <link rel="canonical" href={meta.canonical} />
          <meta property="og:site_name" content="Leeds Gig Tickets" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://static.leedsgigtickets.co.uk/favicon.ico" />
          <meta property="og:url" content={meta.canonical} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
        </Helmet>
        <ScrollToTop />
        <Container fluid="true" className="p-0">
          <AppNavbar />
          <Routes>
            <Route exact path="/" element={<AppHome />} />
            <Route path="/tickets/:id" element={<AppEvent />} />
            <Route path="/venues/:id" element={<AppVenue />} />
          </Routes>
          <AppFooter />
        </Container>
      </Router>
    )
  };

};

export default App;
