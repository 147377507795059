import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Image from "react-bootstrap/Image";

import { isMobile } from "react-device-detect";

class AppTicket extends React.Component {

  render() {

    var url;

    if (isMobile) {
      url = 'https://' + this.props.ticket.url;
    } else {
      url = 'https://' + this.props.ticket.web_url;
    }

    const title = 'Buy ' + this.props.title + ' tickets from ' + this.props.ticket.agency_name;

    return (
      <Row className="py-2">
        <Col xs={4} sm={4} md={4} lg={3}>
          <a href={url} target="_blank" rel="noopener noreferrer" title={title}>
            <Image
              src={'https://static.leedsgigtickets.co.uk/agency/' + this.props.ticket.agency_id + '.jpg'}
              width="100" height="100" className="rounded img-thumbnail"
              title={title} alt={this.props.ticket.agency_name + ' logo'} />
          </a>
        </Col>
        <Col xs={8} sm={8} md={8} lg={9}>
          <h2>
            <a href={url} target="_blank" rel="noopener noreferrer"
              title={title}>{this.props.ticket.agency_name}</a>
          </h2>
          <p>
            <a href={url} target="_blank" rel="noopener noreferrer"
              title={title}>Tickets on-sale - buy now</a>
          </p>
        </Col>
      </Row>
    )
  };
};

export default AppTicket;
