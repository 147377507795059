import React from "react";

import Container from "react-bootstrap/Container";

class AppFooter extends React.Component {

  render() {
    return (
      <Container className="p-3 mt-5">
        <p>
          All content &copy; {(new Date().getFullYear())} Leeds Gig Tickets
        </p>
        <p className="small text-muted">
          We do not sell Leeds gig tickets and any sales contract you make is with the ticket
          agent. All the links we provide are to vetted and well-established online agencies and the majority of
          these are primary official ticket agents, while a handful are ticket exchanges. Our links to ticket
          agents do not imply our endorsement (nor them of us). Prices are intended to be indicative only and may
          not include postage or booking fees. Prices will vary between ticket agents. Please check you are buying
          the correct ticket.
        </p>
      </Container>
    )
  };

};

export default AppFooter;
