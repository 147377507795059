import ls from 'local-storage';

ls.backend(sessionStorage);

const dataDomain = "https://data.leedsgigtickets.co.uk";

export function getId(id, callback, errorFn) {
    var stKey = 'stVenue-' + id, stEvent = ls.get(stKey);
    if (stEvent) {
        callback(stEvent);
    } else {
        console.log("Extracting venue %s from venue list data", id);
        get(
            (data) => {
                const venue = data.cities.Leeds.find(x => x.id === id);
                if (venue) {
                    ls.set(stKey, venue);
                    callback(venue);
                } else {
                    ls.clear(stKey);
                    errorFn({ message: "Could not find venue" });
                }
            },
            (error) => {
                console.log(error.message);
                ls.clear(stKey);
                errorFn(error);
            }
        );
    }
}

export function get(callback, err) {
    var stVenues = ls.get('stVenues');
    if (stVenues) {
        callback(stVenues);
    } else {
        console.log("Downloading venues data from server");
        window.fetch(dataDomain + "/leeds-venues.json")
            .then(res => res.json())
            .then(
                (result) => {
                    ls.set('stVenues', result);
                    callback(result);
                },
                (error) => {
                    ls.clear('stVenues');
                    err(error)
                }
            )
    }
}